.footter{
    width: 100%;
    padding: 20px;
    background-color: #741ab0;
    color:#f2f2f2;
    bottom: 0px;
    position: fixed;
    z-index: 10;
    display: flex;
    justify-content: space-between;
}

.footter a{
    color:#f2f2f2;
}

@media (max-width: 740px) {
    .footter{
        width: 100%;
        padding: 20px;
        background-color: #741ab0;
        color:#f2f2f2;
        bottom: 0px;
        position: fixed;
        z-index: 10;
        display: flex;
        justify-content: space-between;
        font-size: 10px;
    }
    .footter div{
        color:#f2f2f2;
        font-size: 10px;
    }
    .footter a{
        color:#f2f2f2;
        font-size: 10px;
    }
  }