@import "./index.scss";

body{
    background-color: #ffffff ;
    color: #1e1d1fe5;
    font-size: 16px;
}

.custom-tooltip {
  background: transparent !important; /* Hace el fondo transparente */
  box-shadow: none !important; /* Elimina cualquier sombra */
  border: none !important;
  padding: 0 !important;
}

/* Rotación suave */
.leaflet-tile-pane {
  transform: rotate(0deg);
  transition: transform 0.5s ease-in-out;
}

/* Estilos del control de rotación */
.leaflet-control-rotate {
  background: white;
  border-radius: 4px;
  padding: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.leaflet-control-rotate-toggle {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm-1-8l4-4h-3V6h-2v4H7z"/></svg>');
  background-size: 70%;
  background-position: center;
}


.plaholderimg{
    max-width: 400px;
    height: auto;
}
.containerbody{
    margin-top: 0px!important;
    padding-top: 8vh!important;
    padding-bottom: 10%!important;
    padding-left: 0px!important;
    padding-right: 0px!important;
}

.btn-bicci{
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 16px;
    outline: none;
    padding: 10px;
    min-width: 150px;
    margin: 5px;
    background-color:  #741ab0;
    color:#f8f8f8
}
.btn-bicci-small{
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 14px;
    outline: none;
    padding: 10px;
    min-width: 50px;
    margin: 5px;
    background-color:  #741ab0;
    color:#f8f8f8
}

.btn-bicci-prupura-color{
    color: #741ab0!important;
    }
.btn-bicci-prupura-color:hover{
    color: rgb(0, 205, 246)!important; 
    }

.btn-bicci-prupura{
font-weight: 400!important;
fill: #FFFFFF!important;
color: #FFFFFF!important;
background-color: #741ab0!important;
}


.btn-bicci-prupura:hover{
    background-color: rgb(0, 205, 246)!important;
    color: #fff!important;
}

.btn-globalhover-calipso:hover{
    font-weight: 400;
    fill: #FFFFFF;
    color: #FFFFFF;
    background-color: rgb(0, 205, 246);
}


.btn-globalhover-prupura:hover{
    background-color:  #741ab0;
    color: #fff;
}

.card-type2{
    width: 100%;
}

.bodyriderhome{
    padding-bottom: 100px;
}
.landScreend-topcontainer {
  width: 100% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  background-color: #fff;
  z-index: 33 !important;
  top: 0px;
  height: 5em;
  padding-top: 5px;
  position: fixed;
  z-index: 1;
  display: none !important;
}

.menuScreend-topcontainer {
  display: unset;
}

.topbar {
  max-width: 100%;
}

  div.ui.fluid.container.topcontainer {
    width: 100% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 0px !important;
    position: fixed;
    z-index: 33 !important;
    top: 0px;
    // height: 80px;
    // padding-top: 1em;
    box-shadow: 0 -10px 10px 15px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 -10px 10px 15px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 -10px 10px 15px rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

@media only screen and (max-width: 930px){
    body{
    margin: 0px;
}
}